import {LinksFunction} from '@remix-run/react/routeModules';
import {ReactElement} from 'react';
import {
    json,
    Links,
    LiveReload,
    LoaderFunction,
    Meta,
    Outlet,
    Scripts,
    ScrollRestoration,
    useLoaderData,
    useMatches,
} from 'remix';
import type {MetaFunction} from 'remix';

import {getLocale, getTranslations, LocaleInfo} from '~/helpers/i18n/getLocale';
import {Translations} from '~/helpers/language';

import styles from './global.css';

interface EnvironmentVariables {
    TEST?: string;
}

export const meta: MetaFunction = () => {
    return {
        title: 'ClickBookDrive - Compare car hire deals instantly and effortlessly',
    };
};

export const links: LinksFunction = () => {
    return [
        {rel: 'stylesheet', href: styles},
        {rel: 'stylesheet', href: 'https://rsms.me/inter/inter.css'},
    ];
};

export const loader: LoaderFunction = async ({request}) => {
    const locale = getLocale(request);
    const language = await getTranslations(locale.language);
    return json({
        locale,
        ENV: {},
        language,
    });
};

export default function App(): ReactElement {
    const data = useLoaderData<{
        locale: LocaleInfo;
        ENV: EnvironmentVariables;
        language: Translations;
    }>();
    console.log('we are here');
    const matches = useMatches();

    // If at least one route wants to hydrate, this will return true
    const skipHydrate = matches.some((match) => match.handle?.skipHydrate);
    console.log('we got here');
    return (
        <html lang={data.locale.language}>
            <head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1"
                />
                <Meta />
                <Links />
            </head>
            <body>
                <Outlet />
                <ScrollRestoration />
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                        window.CB = {};
                        window.CB.ENVS = ${JSON.stringify(data.ENV)}
                        window.CB.locale = ${JSON.stringify(data.locale)}
                        window.CB.language = ${JSON.stringify(data.language)}`,
                    }}
                />
                {skipHydrate ? null : <Scripts />}
                {process.env.NODE_ENV === 'development' && <LiveReload />}
            </body>
        </html>
    );
}
